import React, { useState } from "react"
import "./OnboardingScreen.css"
import FormInput from "../components/FormInput"
import requests from "../api/requests"
import Button from "../components/Button"
import { useSnackbar } from "react-simple-snackbar"
import {
  DEFAULT_ERROR_MESSAGE,
  errorSnackBar,
} from "../components/snackBarOptions"
import { useLocation, useNavigate } from "react-router-dom"

import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

function EmailScreen() {
  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [openErrorSnackbar] = useSnackbar(errorSnackBar)

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const source = searchParams.get("source")

  const navigate = useNavigate()

  const input = {
    id: 1,
    name: "email",
    type: "email",
    placeholder: "Email",
    errorMessage: "Preencha com um email válido",
    label: "Email",
    required: true,
    autoFocus: true,
  }

  const checkEmail = async (e) => {
    e.preventDefault()

    const formElement = e.target
    const isValid = formElement.checkValidity()
    if (!isValid) {
      openErrorSnackbar("Preencha com um email válido")
      return
    }
    try {
      setIsLoading(true)
      const resp = await requests.checkEmail(email)
      const { data } = resp

      if (data.isMember) {
        navigate("/signin", { state: { email: email, name: data.name } })
        return
      } else {
        if (data.isAlumyMember) {
          navigate("/signup", {
            state: {
              email: email,
              source: source,
              name: data.alumyName,
              isAlumyMember: data.isAlumyMember,
              acceptNewMembers: data.acceptNewMembers,
            },
          })
          return
        }

        navigate("/signup", {
          state: {
            email: email,
            source: source,
            acceptNewMembers: data.acceptNewMembers,
          },
        })
        return
      }
    } catch (error) {
      openErrorSnackbar("O email informado não é válido")
    } finally {
      setIsLoading(false)
    }
  }

  const onChange = (e) => {
    setEmail(e.target.value)
  }

  return (
    <div className="onboardingScreen">
      <div className="onboardingScreen__background"></div>
      <div className="onboardingScreen__gradient"></div>
      <div className="onboardingScreen__body">
        <div className="onboardingScreen__form">
          <h1>Bem vindo ao Cosmo Clube!</h1>
          <h2>Informe seu email para continuar</h2>
          <form onSubmit={checkEmail} noValidate autoComplete="off">
            <FormInput
              key={input.id}
              {...input}
              value={email}
              onChange={onChange}
              icon={faEnvelope}
            />
            <Button text="Próximo" isLoading={isLoading} />
          </form>
        </div>
      </div>
    </div>
  )
}

export default EmailScreen
