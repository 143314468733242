import React, { useEffect, useState } from "react"
import Button from "../components/Button"
import "./OnboardingScreen.css"
import "./UnpaidScreen.css"
import requests from "../api/requests"
import { errorSnackBar } from "../components/snackBarOptions"
import { useSnackbar } from "react-simple-snackbar"
import { useNavigate } from "react-router-dom"
import { logout } from "../app/features/userSlice"
import { useDispatch } from "react-redux"

function UnpaidScreen() {
  const [isLoading, setIsLoading] = useState(false)
  const [openErrorSnackbar] = useSnackbar(errorSnackBar)
  const [subscriptionStatus, setSubscriptionStatus] = useState(null)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const featchUserSubscription = async () => {
      const subscriptions = await requests.getUserSubscription()
      setSubscriptionStatus(subscriptions?.data?.status)
      if (
        subscriptions?.data?.status &&
        (subscriptions.data.status === "active" ||
          subscriptions.data.status === "trialing")
      ) {
        navigate("/")
        return
      }
    }

    featchUserSubscription()
  }, [navigate])

  const loadPortal = async () => {
    let resp = null
    try {
      setIsLoading(true)
      resp = await requests.createPortalSession({
        returnURL: window.location.origin,
      })
    } catch (error) {
      openErrorSnackbar("Erro ao acessar o portal de pagamento")
    } finally {
      setIsLoading(false)
    }

    if (resp?.data?.url) {
      window.location.href = resp.data.url
    }
  }

  const handleLogout = async () => {
    try {
      await requests.logout()
      dispatch(logout())
    } catch (error) {
      openErrorSnackbar("Ocorreu um erro inexperado")
    }
  }

  const canceled = (
    <div className="unpaidScreen">
      <h1>Assinatura Cancelada</h1>
      <p>
        Sua assinatura está cancelada e as vagas para o Cosmo Clube estão
        esgotadas.
      </p>
      <p>
        Você só consiguirá voltar para o Cosmo quando novas vagas forem abertas.
      </p>
      <Button
        onClick={(e) => handleLogout()}
        text="Voltar"
        isLoading={isLoading}
      />
    </div>
  )

  return (
    <div className="onboardingScreen">
      <div className="onboardingScreen__background"></div>
      <div className="onboardingScreen__gradient"></div>
      <div className="onboardingScreen__body">
        {subscriptionStatus === "canceled" ? (
          canceled
        ) : (
          <div className="unpaidScreen">
            <h1>Seu pagamento falhou</h1>
            <p>
              Para continuar acessando o Cosmo Clube, por favor atualize a forma
              de pagamento clicando no botão abaixo
            </p>
            <Button
              onClick={(e) => loadPortal()}
              text="Atualizar Pagamento"
              isLoading={isLoading}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default UnpaidScreen
